<template>
                <el-table
                  ref="inputTable"
                  class="table-responsive table-flush position-table table-tablet"
                  header-row-class-name="thead-light"
                  :data="tabletItems"
                  row-key="id"
                  :row-class-name="tableRowClassName"
                  style="width: 100%; overflow: auto"
                >
                <el-table-column type="expand" width="50">
                  <template slot-scope="props">
                    <el-row>
                      <el-col>
                        <table>
                          <tr>
                            <th class="expanded-header">{{ $t('message.width') }}</th>
                            <th class="expanded-header">{{ $t('message.height') }}</th>
                            <th class="expanded-header">{{ $t('message.unit') }}</th>
                            <th class="expanded-header">{{ $t('message.id') }}</th>
                            <th class="expanded-header">{{ $t('message.Konfektion') }}</th>
                            <th class="expanded-header">{{ $t('message.additionalInformation') }}</th>
                            <th class="expanded-header">{{ $t('message.result_customer') }}</th>
                            <th class="expanded-header">{{ $t('message.komm_orte') }}</th>
                          </tr>
                          <tr>
                            <td class="expanded-row">{{ props.row.width }}</td>
                            <td class="expanded-row">{{ props.row.height }}</td>
                            <td class="expanded-row">{{ props.row.unit }}</td>
                            <td class="expanded-row">{{ props.row.pos.key }}</td>
                            <td class="expanded-row">
                              <div
                                v-if="props.row.pos.pt_konfektionsstatus == 'green'"
                                class="konfektion konfektion-green"
                              ></div>
                              <div
                                v-else-if="props.row.pos.pt_konfektionsstatus == 'yellow'"
                                class="konfektion konfektion-yellow"
                              ></div>
                              <div
                                v-else-if="props.row.pos.pt_konfektionsstatus == 'red'"
                                class="konfektion konfektion-red"
                              ></div>
                              <div v-else class="konfektion"></div>
                              <i
                                v-if="props.row.pos.pt_hilfe"
                                class="fas fa-bell faa-shake animated"
                                :title="props.row.pos.pt_hilfe"
                              ></i>
                            </td>
                            <td class="expanded-row left">{{ props.row.pos.cl_info }}</td>
                            <td class="expanded-row">{{ props.row.rueck_result_customer }}</td>
                            <td class="expanded-row">
                              <el-select
                                multiple
                                collapse-tags
                                class="select-primary"
                                :value="props.row.komm_orte ? props.row.komm_orte.split(',') : []"
                                @input="(val) => onKommOrteChange(props.row, val)"
                                :placeholder="$t('message.komm_orte')"
                                v-if="props.row.mat_no"
                              >
                                <el-option
                                  v-for="option in globalPlaces"
                                  class="select-primary"
                                  :value="option.id"
                                  :label="option.name"
                                  :key="option.id"
                                ></el-option>
                              </el-select>
                            </td>
                          </tr>
                        </table>
                      </el-col>
                    </el-row>
                  </template>
                </el-table-column>
                  <el-table-column :label="$t('message.comNote')" width="120">
                    <template v-slot="{ row }">
                      <el-row>
                        <el-col :span="4">
                          <el-checkbox
                            :disabled="(row.pos.rel_business === 0 || 
                                        row.additional === false || 
                                        (row.komm_bemerkung && row.komm_bemerkung_checked === null)
                                       )"
                            :value="row.state_komm === 'KOMMISSIONIERT'"
                            @change="(value) => changeState(row, value)"
                            class="checkbox-lg"
                          ></el-checkbox>
                        </el-col>
                        <el-col :span="4">
                          <base-button
                            type="link"
                            size="sm"
                            @click="showComments(row)"
                            :disabled="project.state === 'ARCHIVED'"
                          >
                            <span class="btn-inner--icon">
                              <i v-if="row.komm_bemerkung" class="fas fa-comment"></i>
                              <i v-else class="far fa-comment text-black"></i>
                            </span>
                          </base-button>
                        </el-col>
                        <el-col
                          :span="4"
                          v-if="row.additional && row.state_komm != 'KOMMISSIONIERT'"
                        >
                          <base-button
                            type="link"
                            size="sm"
                            @click="edit(row)"
                            :disabled="project.state === 'ARCHIVED'"
                          >
                            <span class="btn-inner--icon">
                              <i class="fas fa-edit text-black"></i>
                            </span>
                          </base-button>
                          <base-button
                            type="link"
                            size="sm"
                            @click="askDelete(row)"
                            :disabled="project.state === 'ARCHIVED'"
                          >
                            <span class="btn-inner--icon">
                              <i class="fas fa-trash text-black"></i>
                            </span>
                          </base-button>
                        </el-col>
                        <el-col
                          :span="4"
                          v-if="!row.additional">
                            <el-tooltip
                              :content="$t('position.go-to-bhb')"
                              placement="top"
                            >
                              <base-button
                                type="link"
                                size="sm"
                                @click="
                                  $router.push({
                                    path: `/project/${$route.params.id}/bhb/${row.position_id}`,
                                  })
                                "
                              >
                                <span class="btn-inner--icon">
                                  <i class="fas fa-edit text-black"></i>
                                </span>
                              </base-button>
                            </el-tooltip>
                        </el-col>
                      </el-row>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Layout"
                    width="100"
                    align="center"
                  >
                    <template v-slot="{row}">
                      <img v-if="hasImageUrl(row, 'designs')" 
                          :src="imageUrl(row, 'designs')" 
                          style="width: auto; max-width: 100%; max-height: 130px; cursor: pointer;" 
                          @click="openImage(row)"/>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('message.ride')"
                    :prop="'pos.ride.vehicle.type'"
                    sortable
                    width="120"
                  >
                  <template v-slot="{row}">
                    <base-button
                      type="link"
                      size="sm"
                      class="text-left"
                      @click="showRides(row)"
                    >
                      {{ row.pos.ride 
                      ? (row.pos.ride.notice ? row.pos.ride.vehicle.type +' / '+ row.pos.ride.notice : row.pos.ride.vehicle.type) 
                      : ''}}
                    </base-button>
                  </template>
                  </el-table-column>

                  <el-table-column
                    sortable
                    label="PT-NR."
                    width="100"
                    prop="pos.pt_number"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="category.name"
                    :label="$t('global.category')"
                    width="150"
                    sortable
                  >
                    <template v-slot="{row}">
                      {{ row | category(currentLocale) }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="pos.area.name"
                    :label="$t('message.positionBHB')"
                    width="120"
                    sortable
                  >
                    <template v-slot="{row}">
                      <div v-if="row.pos.area">{{ row.pos.area.name }}</div>
                      <div v-if="row.pos.bhb_position">({{ row.pos.bhb_position.name }})</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('message.materialConstruction')"
                    width="220"
                    sortable
                    :sort-method="sortMaterial"
                  >
                    <template v-slot="{ row }">
                      <div v-if="row.article && !row.additional">
                        {{ row | material(currentLocale) }}
                      </div>
                      <div v-if="row.additional">
                        {{ row.article }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('message.amount')"
                    align="center"
                    header-align="center"
                    width="100"
                    prop="quantity"
                    sortable
                  >
                    <template v-slot="{ row }">
                      {{ row.quantity | number }}
                    </template>
                  </el-table-column>
                  
                </el-table>
 
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { setTimeout } from "timers";
import { isImage } from "@/util/data";

export default {
  name: "PickingListMobile",
  computed: {
    ...mapGetters([
      "project",
      "positions",
      "projectAddressPositions",
      "currentProjectAddress",
      "pickingListPositions",
      "kommStateValues",
      "kommFilterValue",
      "currentUserRights",
      "openProjects",
      "currentUser",
      "currentLocale",
      "authorisedStateValues",
      "globalOptions"
    ]),
    globalPlaces() {
      return this.globalOptions.filter(o => o.type == 'PICKING')
                                .map(g => {
                                  return {id: String(g.id), name: g.name}
                                })
                                .sort((a, b) => 
                                  a.name.localeCompare(b.name)
                                );
    },
  },
  props: {
    tabletItems: {
			type: [Array, Object],
    }
  },
  data() {
    return {
    };
  },
  watch: {
    positions(val) {
      if (val !== null) {
        setTimeout(() => {}, 5000);
      }
    },
  },
  methods: {
    ...mapActions([
      "updatePositionSort",
      "updateProject",
      "loadAdditional",
      "getFullProject",
      "getProjectPersons",
      "loadGlobalOptions"
    ]),
    sortMaterial(a, b) {
      let list1 = a.additional === true ? a.article : a.variant.InternalDescription;
      let list2 = b.additional === true ? b.article : b.variant.InternalDescription;

      if(list1 < list2) { return -1; }
      if(list1 > list2) { return 1; }

      return 0;
    },
    showRides(row) {
      this.$emit('showRidesClick', row);
    },
    showComments(row) {
      this.$emit('showCommentsClick', row);
    },
    edit(row) {
      this.$emit('editClick', row);
    },
    askDelete(row) {
      this.$emit('deleteClick', row);
    },
    onKommOrteChange(row, e) {
      this.$emit('onKommOrteChangeClick', {item: row, value: e});
    },
    changeState(row, value = null) {
      this.$emit('changeStateClick', {item: row, value: value});
    },
    openImage(row) {
      this.$emit('openImageClick', row);
    },
    imageUrl(item, path) {
      if (item.pos.documents) {
        const found = item.pos.documents
          .filter((doc) => doc.path === path)
          .reverse();
        if (found.length > 0) {
          if (isImage(found[0].mimetype)) {
            return found[0].url;
          }
          return "/app/img/empty.png";
        }
      }
      return "/app/img/empty.png";
    },
    hasImageUrl(item, path) {
      if (item.pos.documents && item.type !== 'KON') {
        const found = item.pos.documents
          .filter((doc) => doc.path === path)
          .reverse();
        if (found.length > 0) {
          if (isImage(found[0].mimetype)) {
            return true;
          }
          return false;
        }
      }
      return false;
    },
    tableRowClassName({ row, rowIndex }) {
      let classes = "no-hover";
      if (this.$route.params.positionId) {
        let pos_item = parseInt(this.$route.params.positionId);
        if (pos_item === row.position_id || pos_item === row.id) classes += " highlighted";
      }
      if (row.state_komm === "KOMMISSIONIERT") classes += " commissioned";
      if (row.pos.rel_business === 0) classes += " not-approved";
      if (rowIndex % 2 == 0) classes += " striped";
      return classes;
    }
  },
  async mounted() {
    this.loadGlobalOptions();
  },
};
</script>
<style>
.table-tablet table.el-table__body td {
  padding-left: 0.5rem;
}
.table-tablet table.el-table__body .el-table__expanded-cell {
  background-color: #e8ebef;
}
.table-tablet table.el-table__body .expanded-row {
  text-align: center;
}.table-tablet table.el-table__body .expanded-row.left {
  text-align: left;
}
.table-tablet table.el-table__body .expanded-header {
  padding-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.65rem;
  font-weight: 600;
  letter-spacing: 1px;
}
.checkbox-lg .el-checkbox ::before,
.checkbox-lg .el-checkbox ::after {
  top: 0.8rem;
  width: 1.55rem;
  height: 1.55rem;
}

.checkbox-lg .el-checkbox {
  padding-top: 13px;
  padding-left: 6px;
}

.checkbox-xl .el-checkbox ::before,
.checkbox-xl .el-checkbox ::after {
  top: 1.2rem;
  width: 1.85rem;
  height: 1.85rem;
}

.project-selection {
  width:100%;
}

.el-table__footer-wrapper > .el-table__footer > tbody > tr > td {
  padding-right: 0;
}

.checkbox-xl .el-checkbox {
  padding-top: 23px;
  padding-left: 10px;
}
.el-table .striped {
  background: #fafafa !important;
}
.el-table .commissioned {
  background: #dddddd !important;
}
.el-table .not-approved {
  background: rgba(255,0,0,0.15) !important;
}
.ride-name {
  display: inline-block;
  width: 150px;
  margin: 5px 0;
}
.el-table .highlighted {
  background: rgba(133, 193, 248, 0.3) !important;
}
.konfektion {
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.konfektion-red {
  background-color: #f00;
}
.konfektion-yellow {
  background-color: #ff0;
}
.konfektion-green {
  background-color: #0f0;
}
</style>
