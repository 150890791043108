<template>
  <div>
    <base-header class="pb-6">
    <div class="py-2 tablet-show"></div>
      <div class="row align-items-center py-4 tablet-hide">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button
            type="primary"
            icon
            round
            size="sm"
            @click="showCopyAdditionalMaterialsModal"
            style="height: 30px"
            :disabled="project && project.state === 'ARCHIVED'"
          >
            <i class="fas fa-copy p-0"></i>
          </base-button>
          <base-button
            size="sm"
            type="primary"
            @click="edit(null)"
            :disabled="project && project.state === 'ARCHIVED'"
          >
            {{ $t("message.additionalWorkingAids") }}
          </base-button>
          <base-button
            size="sm"
            type="primary"
            @click="toggleCheck()"
            :disabled="
              (project && project.state === 'ARCHIVED') ||
              currentUserRights.includes('picking-list.checked.canchange')
            "
          >
            <template v-if="project && project.komm_checked">
              {{ $t("message.komm_checked") }}{{ project.komm_checked | date }}
              {{ $t("message.komm_checked_by") }}
              {{ project.komm_checked_user.name }}
            </template>
            <template v-else> {{ $t("message.list_checked") }} </template>
          </base-button>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <card no-body>
            <template slot="header">
              <div class="row">
                <div class="col-6">
                  <h3 class="mb-0">{{ $t("message.pickingList") }}</h3>
                </div>
                <div class="col-6 text-right">

                  <span class="mr-2 tablet-show">
                    <el-tooltip :content="$t('message.copy')" placement="top">
                      <base-button
                        type="primary"
                        icon
                        round
                        size="sm"
                        @click="showCopyAdditionalMaterialsModal"
                        style="height: 30px"
                        :disabled="project && project.state === 'ARCHIVED'"
                      >
                        <i class="fas fa-copy p-0"></i>
                      </base-button>
                    </el-tooltip>
                    <base-button
                      size="sm"
                      type="primary"
                      @click="edit(null)"
                      :disabled="project && project.state === 'ARCHIVED'"
                    >
                      {{ $t("message.additionalWorkingAids") }}
                    </base-button>
                    <base-button
                      size="sm"
                      type="primary"
                      @click="toggleCheck()"
                      :disabled="
                        (project && project.state === 'ARCHIVED') ||
                        currentUserRights.includes('picking-list.checked.canchange')
                      "
                    >
                      <template v-if="project && project.komm_checked">
                        {{ $t("message.komm_checked") }}{{ project.komm_checked | date }}
                        {{ $t("message.komm_checked_by") }}
                        {{ project.komm_checked_user.name }}
                      </template>
                      <template v-else> {{ $t("message.list_checked") }} </template>
                    </base-button>
                  </span>
        
                  <compact-mode-toggle v-model="compactMode" class="tablet-hide" />
                  <base-button
                    class="pr-1"
                    size="sm"
                    type="primary"
                    @click="openExportModal"
                    v-if="
                      items.length > 0 &&
                      currentUserRights.includes('project.kommissionierungsliste.canexport')
                    "
                  >
                    {{ $t("message.export") }}
                  </base-button>
                </div>
              </div>
            </template>
            <template slot="footer">
              <div class="row">
                <div class="col-12 text-right"></div>
              </div>
            </template>

            <div class="row">
              <div class="col-12 d-md-flex">
                
                  <el-input
                  class="col-8"
                    v-model="searchText"
                    :placeholder="$t('global.search')"
                  />
                <div class="col-4 d-md-flex">
                  <el-input class="mr-2" :placeholder="$t('data_input.add_picking_placeholder')" v-model="newPickingValue" />
                  <base-button size="sm" type="primary" @click="saveNewOption('PICKING')">{{
                    $t('global.save')
                  }}</base-button>
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="col m-3">

                <el-select
                  filterable
                  multiple
                  collapse-tags
                  class="mr-2 mb-1 select-primary"
                  :value="filterRides"
                  @input="onFilterRidesChanged"
                  :placeholder="$t('message.ride')"
                >
                  <el-option
                    v-for="option in ridesOptions"
                    class="select-primary"
                    :value="option"
                    :label="option"
                    :key="option"
                  ></el-option>
                </el-select>

                <el-select
                  filterable
                  multiple
                  collapse-tags
                  class="mr-2 select-primary"
                  :value="filterLocations"
                  @input="onFilterLocationsChanged"
                  :placeholder="$t('message.location')"
                >
                  <el-option
                    v-for="option in locationsOptions"
                    class="select-primary"
                    :value="option"
                    :label="option"
                    :key="option"
                  ></el-option>
                </el-select>

                <el-select
                  filterable
                  multiple
                  collapse-tags
                  class="mr-2 select-primary"
                  :value="filterPtNumber"
                  @input="onFilterPtNumberChanged"
                  :placeholder="$t('message.PTNumberRange')"
                >
                  <el-option
                    v-for="option in ptNumbers"
                    class="select-primary"
                    :value="option"
                    :label="option"
                    :key="option"
                  ></el-option>
                </el-select>
                <el-select
                  filterable
                  multiple
                  collapse-tags
                  class="mr-2 select-primary"
                  :value="filterCategories"
                  @input="onFilterCategoryChanged"
                  :placeholder="$t('global.category')"
                >
                  <el-option
                    v-for="option in categories"
                    class="select-primary"
                    :value="option"
                    :label="option"
                    :key="option"
                  ></el-option>
                </el-select>
                <el-select
                  filterable
                  multiple
                  collapse-tags
                  class="mr-2 select-primary"
                  :value="filterArea"
                  @input="onFilterAreaChanged"
                  :placeholder="$t('data_input.area_label')"
                >
                  <el-option
                    v-for="option in areaOptions"
                    class="select-primary"
                    :value="option"
                    :label="option"
                    :key="option"
                  ></el-option>
                </el-select>
                <el-select
                  filterable
                  multiple
                  collapse-tags
                  class="select-primary mr-2"
                  v-model="filterValue"
                  placeholder="Status"
                >
                  <el-option
                    v-for="(option, index) in stateFilterValues"
                    class="select-primary ml-1"
                    :value="index"
                    :label="option"
                    :key="index"
                  >
                  </el-option>
                </el-select>
                <el-select
                  filterable
                  multiple
                  class="mr-2 select-primary"
                  v-model="kommOrte"
                  :placeholder="$t('message.komm_orte')">
                  <el-option v-for="option in globalPlaces" 
                    :key="option.id"
                    :label="option.name" 
                    :value="option.id">
                  </el-option>
                </el-select>

                <base-checkbox
                  v-model="showKommisioniert"
                  class="mr-2"
                  style="display: inline-block"
                >
                  {{ $t("tasks.show-done-tasks") }}
                </base-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <picking-list-mobile
                  class="tablet-show"
                  :tabletItems=paginatedItems
                  @changeStateClick="(val) => changeState(val.item, val.value)"
                  @showCommentsClick="showComments"
                  @showRidesClick="showRides"
                  @openImageClick="openImage"
                  @onKommOrteChangeClick="(val) => onKommOrteChange(val.item, val.value)"
                  @editClick="edit"
                  @deleteClick="askDelete"
                />
                <el-table
                  ref="inputTable"
                  class="table-responsive table-flush position-table tablet-hide"
                  header-row-class-name="thead-light"
                  :data="paginatedItems"
                  row-key="id"
                  :summary-method="getSummaries"
                  :show-summary="searchText !== ''"
                  :row-class-name="tableRowClassName"
                  style="width: 100%; overflow: auto"
                >
                  <el-table-column :label="$t('message.comNote')" width="150">
                    <template v-slot="{ row }">
                      <el-row>
                        <el-col :span="4">
                          <el-checkbox
                            :disabled="(row.pos.rel_business === 0 || 
                                        row.additional === false || 
                                        (row.komm_bemerkung && row.komm_bemerkung_checked === null)
                                       )"
                            :value="row.state_komm === 'KOMMISSIONIERT'"
                            @change="(value) => changeState(row, value)"
                            class="checkbox-lg"
                          ></el-checkbox>
                        </el-col>
                        <el-col :span="row.additional ? 12 : 20">
                          <base-button
                            type="link"
                            size="md"
                            @click="showComments(row)"
                            :disabled="project.state === 'ARCHIVED'"
                          >
                            <span class="btn-inner--icon">
                              <i v-if="row.komm_bemerkung" class="fas fa-comment"></i>
                              <i v-else class="far fa-comment text-black"></i>
                            </span>
                          </base-button>
                        </el-col>
                        <el-col
                          :span="8"
                          v-if="row.additional && row.state_komm != 'KOMMISSIONIERT'"
                        >
                          <base-button
                            type="link"
                            size="sm"
                            @click="edit(row)"
                            :disabled="project.state === 'ARCHIVED'"
                          >
                            <span class="btn-inner--icon">
                              <i class="fas fa-edit text-black"></i>
                            </span>
                          </base-button>
                          <base-button
                            type="link"
                            size="sm"
                            @click="askDelete(row)"
                            :disabled="project.state === 'ARCHIVED'"
                          >
                            <span class="btn-inner--icon">
                              <i class="fas fa-trash text-black"></i>
                            </span>
                          </base-button>
                        </el-col>
                      </el-row>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Layout"
                    width="130"
                    align="center"
                  >
                    <template v-slot="{row}">
                      <img v-if="hasImageUrl(row, 'designs')" 
                          :src="imageUrl(row, 'designs')" 
                          style="width: auto; max-width: 100%; max-height: 180px; cursor: pointer;" 
                          @click="openImage(row)"/>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('message.ride')"
                    :prop="'pos.ride.vehicle.type'"
                    sortable
                    width="120"
                  >
                  <template v-slot="{row}">
                    <base-button
                      type="link"
                      size="sm"
                      class="text-left"
                      @click="showRides(row)"
                    >
                      {{ row.pos.ride 
                      ? (row.pos.ride.notice ? row.pos.ride.vehicle.type +' / '+ row.pos.ride.notice : row.pos.ride.vehicle.type) 
                      : ''}}
                    </base-button>
                  </template>
                  </el-table-column>
                  <el-table-column v-if="!compactMode">
                    <template v-slot="{ row }">
                      <el-tooltip
                        :content="$t('position.go_to_im')"
                        placement="top"
                        v-if="!row.additional"
                      >
                        <base-button
                          type="link"
                          size="sm"
                          @click="
                            $router.push({
                              path: `/project/${$route.params.id}/bhb/${row.position_id}`,
                            })
                          "
                        >
                          <span class="btn-inner--icon">
                            <i class="fas fa-edit text-black"></i>
                          </span>
                        </base-button>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column label="Fahrt" width="120" sortable>
                <template v-slot="{row}">
                  {{row.pos.ride ? row.pos.ride.departure : null | date }}
                </template>
              </el-table-column> -->

                  <el-table-column
                    v-if="!compactMode"
                    sortable
                    prop="pos.key"
                    :label="$t('message.id')"
                    width="100"
                  >
                  </el-table-column>
                  <el-table-column
                    sortable
                    :label="$t('position.pt_number')"
                    width="170"
                    prop="pos.pt_number"
                  >
                  </el-table-column>
                  <el-table-column
                    v-if="!compactMode"
                    :label="$t('message.Konfektion')"
                    width="100"
                  >
                    <template v-slot="{ row }">
                      <div
                        v-if="row.pos.pt_konfektionsstatus == 'green'"
                        class="konfektion konfektion-green"
                      ></div>
                      <div
                        v-else-if="row.pos.pt_konfektionsstatus == 'yellow'"
                        class="konfektion konfektion-yellow"
                      ></div>
                      <div
                        v-else-if="row.pos.pt_konfektionsstatus == 'red'"
                        class="konfektion konfektion-red"
                      ></div>
                      <div v-else class="konfektion"></div>
                      <i
                        v-if="row.pos.pt_hilfe"
                        class="fas fa-bell faa-shake animated"
                        :title="row.pos.pt_hilfe"
                      ></i>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="category.name"
                    :label="$t('global.category')"
                    width="220"
                    sortable
                  >
                    <template v-slot="{row}">
                      {{ row | category(currentLocale) }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="pos.area.name"
                    :label="$t('message.positionBHB')"
                    width="200"
                    sortable
                    :sort-method="sortPosition"
                  >
                    <template v-slot="{row}">
                      <div v-if="row.pos.area">{{ row.pos.area.name }}</div>
                      <div v-if="row.pos.bhb_position">({{ row.pos.bhb_position.name }})</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('message.materialConstruction')"
                    width="250"
                    sortable
                    :sort-method="sortMaterial"
                  >
                    <template v-slot="{ row }">
                      <div v-if="row.article && !row.additional">
                        {{ row | material(currentLocale) }}
                      </div>
                      <div v-if="row.additional">
                        {{ row.article }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('message.amount')"
                    align="right"
                    header-align="right"
                    width="100"
                    prop="quantity"
                    sortable
                  >
                    <template v-slot="{ row }">
                      {{ row.quantity | number }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('message.unit')"
                    align="right"
                    header-align="right"
                    width="100"
                  >
                    <template v-slot="{ row }">
                      {{ row.unit }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="right"
                    :label="$t('message.width')"
                    prop="width"
                    width="100"
                  >
                    <template v-slot="{ row }">
                      {{ row.width | number }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="right"
                    :label="$t('message.height')"
                    width="100"
                    prop="height"
                  >
                    <template v-slot="{ row }">
                      {{ row.height | number }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('message.additionalInformation')"
                    prop="pos.cl_info"
                    width="200"
                  ></el-table-column>
                  <el-table-column
                    :label="$t('message.result_customer')"
                    width="160"
                    prop="rueck_result_customer"
                    sortable
                  ></el-table-column>
                  <el-table-column :label="$t('message.komm_orte')" width="250">
                    <template v-slot="{ row }">
                      <el-select
                        multiple
                        class="select-primary"
                        :value="row.komm_orte ? row.komm_orte.split(',') : []"
                        @input="(val) => onKommOrteChange(row, val)"
                        :placeholder="$t('message.komm_orte')"
                        v-if="row.mat_no"
                      >
                        <el-option
                          v-for="option in globalPlaces"
                          class="select-primary"
                          :value="option.id"
                          :label="option.name"
                          :key="option.id"
                        ></el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>

            <div
              class="
                card-footer
                py-4
                d-md-flex
                justify-content-between
                align-items-center
                hasArchiveCheckbox
              "
            >
              <el-pagination
                background
                :current-page="currentPage"
                @current-change="onCurrentChange"
                :total="items.length"
                :page-count="pageCount"
                layout="prev, pager, next"
              >
              </el-pagination>
            </div>
          </card>
        </div>
      </div>
    </div>

    <material-additional
      @change="reloadAdditional"
      @update:show="updateShowWorkingAidModal"
      @saveAndNew="setNewKey"
      :show="showWorkingAidModal"
      :item="item"
    >
    </material-additional>

    <modal :show.sync="isVisibleCopyAdditionalMaterialsModal">
      <h6 slot="header" class="modal-title">
        {{ $t("task.copy-from-project") }}
      </h6>
      <el-select 
        class="project-selection" 
        v-model="projectToCopyFrom"
        filterable
      >
        <el-option
          v-for="project in openProjects"
          :key="project.id"
          :label="project.name"
          :value="project.id"
        />
      </el-select>
      <div class="row mt-3">
        <div class="col-md-3">
          <el-select
            filterable
            class="mr-2 select-primary"
            v-model="rideToCopyFrom"
            :placeholder="$t('message.ride')"
            :label="$t('message.ride')"
          >
            <el-option
              v-for="ride in copyRides"
              class="select-primary"
              :value="ride.id"
              :label="ride.license ? ride.vehicle.type + ' / '  + ride.license : ride.vehicle.type"
              :key="ride.id"
            ></el-option>
          </el-select>
        </div>
        <div class="col-md-3">
          <el-select
            filterable
            class="mr-2 select-primary"
            v-model="locationToCopyFrom"
            :placeholder="$t('message.location')"
          >
            <el-option
              v-for="option in copyLocations"
              class="select-primary"
              :value="option.id"
              :label="option.name"
              :key="option.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <template slot="footer">
        <base-button 
          type="primary" 
          @click="copyAdditionalMaterials"
          :disabled="copyDisabled"
          >{{
          $t("message.copy")
        }}</base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="isVisibleCopyAdditionalMaterialsModal = false"
          >{{ $t("message.cancel") }}</base-button
        >
      </template>
    </modal>

    <modal :show.sync="showDeleteWorkingAidModal">
      <h6 slot="header" class="modal-title">
        {{ $t("message.deleteAdditionalArticle") }}
      </h6>
      <p>{{ $t("message.deleteAdditionalArticleQuestion") }}</p>
      <template slot="footer">
        <base-button
          type="link"
          class="ml-auto"
          @click="showDeleteWorkingAidModal = false"
          >{{ $t("message.no") }}</base-button
        >
        <base-button type="primary" @click="deleteAdditional">{{ $t("message.yes") }}</base-button>
      </template>
    </modal>

    <modal :show.sync="showRidesModal">
      <h6 slot="header" class="modal-title">
        Ladetermine
      </h6>
      <p class="text-bold">{{ ridesList.title }}</p>
      <div v-for="(value, index) in ridesList.rides" :key="index">
        <span class="ride-name">{{ index }}:</span><span> {{ value | datetime }}</span>
      </div>
      <template slot="footer">
        <base-button
          type="link"
          class="ml-auto"
          @click="showRidesModal = false"
          >{{ $t("message.close") }}</base-button
        >
      </template>
    </modal>

    <modal :show.sync="showCommentModal">
      <h6 slot="header" class="modal-title">
        {{ $t("message.notice") }}
      </h6>
      <div>
        <el-input
          type="textarea"
          rows="5"
          placeholder=""
          @change="(value) => changeState(commentItem)"
          v-model="commentItem.komm_bemerkung"
          :disabled="
            !currentUserRights.includes(
              'Kommissionierungsliste_Bemerkungen verfassen'
            ) || project.state === 'ARCHIVED'
          "
        ></el-input>
      </div>
      <div class="mt-3">
        <el-checkbox
          v-if="(this.isPL && commentItem.komm_bemerkung && commentItem.komm_bemerkung_checked === null)"
          @change="checkComment(commentItem)"
          class="checkbox-lg"
          :label="$t('message.comment_checked_by_pl')"
        ></el-checkbox>
        <p v-if="(commentItem.komm_bemerkung_checked !== null)">
          <i class="fas fa-check"></i>
          {{ $t("message.komm_checked") }} {{ commentItem.komm_bemerkung_checked | datetime }}
        </p>
        <p v-if="(!this.isPL && commentItem.komm_bemerkung && commentItem.komm_bemerkung_checked === null)">
          <i class="fas fa-info-circle"></i>
          {{ $t("message.comment_unchecked") }}
        </p>
      </div>
      <p v-if="commentItem.user_komm_ref" class="mt-3">
        {{ $t("message.bhbState.KOMMISSIONIERT") }}: 
        {{ commentItem.user_komm_ref.name }},
        {{ commentItem.date_komm | datetime }}
      </p>
      <template slot="footer">
        <base-button
          type="link"
          class="ml-auto"
          @click="showCommentModal = false"
          >{{ $t("message.close") }}</base-button
        >
      </template>
    </modal>

    <modal :show.sync="showImageModal">
      <h6 slot="header" class="modal-title">
        Layout
      </h6>
      <div class="text-center">
        <img :src="imagePreview" style="max-height: 400px;" />
      </div>
      <template slot="footer">
        <base-button
          type="link"
          class="ml-auto"
          @click="showImageModal = false"
          >{{ $t("message.close") }}</base-button
        >
      </template>
    </modal>

    <picking-list-export-form :show.sync="exportModal" :items="items" />
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { setTimeout } from "timers";
import MaterialAdditional from "./AddMaterialAdditional";
import moment from "moment";
import { debounce, throttle } from "lodash";
import { isImage } from "@/util/data";

import PickingListExportForm from "./PickingListExportForm";
import PickingListMobile from "./PickingListMobile";

export default {
  name: "PickingList",
  components: {
    RouteBreadCrumb,
    MaterialAdditional,
    PickingListExportForm,
    PickingListMobile
  },
  computed: {
    ...mapGetters([
      "project",
      "positions",
      "projectAddressPositions",
      "currentProjectAddress",
      "pickingListPositions",
      "kommStateValues",
      "kommFilterValue",
      "currentUserRights",
      "openProjects",
      "currentUser",
      "currentLocale",
      "authorisedStateValues",
      "rides",
      "globalOptions"
    ]),
    globalPlaces() {
      return this.globalOptions.filter(o => o.type == 'PICKING')
                                .map(g => {
                                  return {id: String(g.id), name: g.name}
                                })
                                .sort((a, b) => 
                                  a.name.localeCompare(b.name)
                                );
    },
    stateFilterValues() {
      const temp = [...new Set(this.kommStateValues)];
      return temp.map((i) => this.$t("message.bhbState." + i));
    },
    filterValue: {
      get() {
        return this.kommFilterValue;
      },
      set(val) {
        this.$store.commit("kommFilterValue", val);
      },
    },
    totalPrice: function () {
      return this.pickingListPositions.reduce((val, pos) => {
        return val + Number.parseFloat(pos.price_total);
      }, 0);
    },
    items() {
      let additional = this.additional;
      if (this.currentProjectAddress) {
        additional = additional.filter(
          (ai) => ai.address.address_id === this.currentProjectAddress
        );
      }

      let not_approved = this.pickingListPositions
        .filter(
          (item) => (item.pos.rel_business === 0 ||
                    item.category.name == 'Werkzeug') &&
                    item.pos.state_bhb !== 'ABGELEHNT' &&
                    (this.filterValue == 0
                    ? true
                    : item.state_komm == this.kommStateValues[this.filterValue - 1])
        );

      let result = this.pickingListPositions
        .filter(
          (item) =>
            (item.pos.rel_business ||
              item.category.name == 'Werkzeug') &&
            (this.filterValue == 0
              ? true
              : item.state_komm == this.kommStateValues[this.filterValue - 1])
        )
        .concat(additional)
        .concat(not_approved);

      result.forEach((el) => {
        if (el.height > 0 && el.width > 0) {
          el["extent"] = (2 * el.width + 2 * el.height) / 1000.0;
        }
      });

      if (this.searchText) {
        const searchText = this.searchText.toLowerCase();
        result = result.filter((r) => {
          return (
            r.pos.pt_number?.toLowerCase().includes(searchText) ||
            r?.pos.key.toLowerCase().includes(searchText) ||
            r?.variant?.InternalDescription?.toLowerCase().includes(searchText) ||
            r?.komm_bemerkung?.toLowerCase().includes(searchText) ||
            r?.pos.bhb_position?.name?.toLowerCase().includes(searchText) ||
            r?.pos.cl_info?.toLowerCase().includes(searchText) ||
            r?.pt_number?.toLowerCase().includes(searchText) ||
            r?.quantity?.toString().includes(searchText) ||
            r?.width?.toString().includes(searchText) ||
            r?.height?.toString().includes(searchText) ||
            (typeof r?.article === "string"
              ? r?.article?.toLowerCase().includes(searchText)
              : false)
          );
        });
      }

      if (!this.showKommisioniert) {
        result = result.filter((item) => item.state_komm !== "KOMMISSIONIERT");
      }

      if (this.filterRides.length > 0) {
        result = result.filter((item) => {
          if (item.pos.ride) {
            return this.filterRides.includes((item.pos.ride.license ? item.pos.ride.vehicle.type + ' / ' + item.pos.ride.license : item.pos.ride.vehicle.type));
          }
          return false;
        });
      }

      if (this.filterLocations.length > 0) {
        result = result.filter((item) => {
          if (item.pos.address) {
            return this.filterLocations.includes(item.pos.address.address.name);
          }
          return false;
        });
      }

      if (this.filterPtNumber.length > 0) {
        result = result.filter((item) =>
          this.filterPtNumber.includes(item.pos.pt_number)
        );
      }

      if (this.filterCategories.length > 0) {
        result = result.filter((item) => {
          if (item.category) {
            return this.filterCategories.includes(item.category.name);
          }
          return false;
        });
      }

      if (this.filterArea.length > 0) {
        result = result.filter((item) => {
          if (item.pos.area) {
            return this.filterArea.includes(item.pos.area.name);
          }
          return false;
        });
      }

      if (this.kommOrte.length > 0) {
        result = result.filter((item) =>
          this.kommOrte.some(
            (i) => item.komm_orte && item.komm_orte.includes(i)
          )
        );
      }
/*
      result = result.sort((a, b) => {
        const ac = a.state_komm === "KOMMISSIONIERT";
        const bc = b.state_komm === "KOMMISSIONIERT";
        if (ac == bc) {
          if (a.additional && !b.additional) return +1;
          if (!a.additional && b.additional) return -1;
          return a.id - b.id;
        }
        return ac ? +1 : -1;
      });
*/

      const ifExtent = [];
      ifExtent["extent"] = "19";

      return result;
    },
    ridesOptions() {
      const items = this.pickingListPositions.concat(this.additional);
      return [
        ...new Set(
          items.map((item) =>
            item.pos.ride ? 
            (item.pos.ride.license ? item.pos.ride.vehicle.type + ' / ' + item.pos.ride.license : item.pos.ride.vehicle.type)
            : "Leer"
          )
        ),
      ];
    },
    locationsOptions() {
      const items = this.pickingListPositions.concat(this.additional);
      return [
        ...new Set(
          items.map((item) =>
            item.pos.address ? item.pos.address.address.name : "Leer"
          )
        ),
      ];
    },
    ptNumbers() {
      const items = this.pickingListPositions.concat(this.additional);
      return [
        ...new Set(
          items.map((item) =>
            item.pos.pt_number ? item.pos.pt_number : "Leer"
          )
        ),
      ];
    },
    categories() {
      const items = this.pickingListPositions.concat(this.additional);
      let cats = [
        ...new Set(
          items.map((item) => (item.category ? item.category.name : "Leer"))
          .filter(i => i !== 'Leer')
        ),
      ];
      return cats;
    },
    areaOptions() {
      const items = this.pickingListPositions.concat(this.additional);
      let areas = [
        ...new Set(
          items.map((item) =>
            item.pos.area ? item.pos.area.name : "Leer"
          )
          .filter(i => i !== 'Leer')
        ),
      ];
      return areas;
    },
    pageCount() {
      return Math.ceil(this.items.length / this.pageSize);
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.items.slice(start, end);
    },
    copyRides() {
      return this.rides
    },
    copyLocations() {
      const items = this.pickingListPositions.concat(this.additional);

      let cPlaces = [...new Set(
          items.map(item => {
            const temp = {};
            temp.id = item.pos.address.address.id;
            temp.name = item.pos.address.address.name;
            return temp;
          })
        )];
      return cPlaces.filter((a, i) => cPlaces.findIndex((s) => a.id === s.id) === i);
    },
    copyDisabled() {
      if(this.rideToCopyFrom === null) return true;
      if(this.locationToCopyFrom === null) return true;
      return false;
    },
    currentPage: {
      get() {
        return this.$store.getters.pickingListCurrentPage;
      },
      set(val) {
        this.$store.commit("SET_PICKING_LIST_CURRENT_PAGE", val);
      },
    },
    searchText: {
      get() {
        return this.search;
      },
      set: throttle(function (val) {
        this.search = val;
      }, 10),
    },
  },
  data() {
    return {
      // project: {},
      compactMode: true,
      enableSorting: true,
      showPriceList: false,
      sel: [],
      changed: [],
      filterText: "Alle",
      showWorkingAidModal: false,
      isVisibleCopyAdditionalMaterialsModal: false,
      showDeleteWorkingAidModal: false,
      showRidesModal: false,
      ridesList: [],
      additional: [],
      item: {},
      itemDelete: null,
      newPickingValue: '',
      kommOrte: [],
      filterRides: [],
      filterLocations: [],
      filterPtNumber: [],
      filterCategories: [],
      filterArea: [],
      exportModal: false,
      pageSize: 10,
      showKommisioniert: false,
      projectToCopyFrom: null,
      rideToCopyFrom: null,
      locationToCopyFrom: null,
      search: "",
      showCommentModal: false,
      commentItem: {},
      nextKey: null,
      isPL: false,
      showImageModal: false,
      imagePreview: null
    };
  },
  watch: {
    positions(val) {
      if (val !== null) {
        setTimeout(() => {}, 5000);
      }
    },
    project() {
      this.reloadAdditional();
    },
  },
  methods: {
    ...mapActions([
      "updatePositionSort",
      "updateProject",
      "loadAdditional",
      "getFullProject",
      "getProjectPersons",
      "loadGlobalOptions"
    ]),
    updateShowWorkingAidModal(val) {
      this.showWorkingAidModal = val;
    },
    sortMaterial(a, b) {
      let list1 = a.additional === true ? a.article : a.variant.InternalDescription;
      let list2 = b.additional === true ? b.article : b.variant.InternalDescription;

      if(list1 < list2) { return -1; }
      if(list1 > list2) { return 1; }

      return 0;
    },
    sortPosition(a, b) {
      let list1 = a.pos.bhb_position !== null ? a.pos.bhb_position.name : '';
      let list2 = b.pos.bhb_position !== null ? b.pos.bhb_position.name : '';

      if(list1 < list2) { return -1; }
      if(list1 > list2) { return 1; }

      return 0;
    },
    showRides(row) {
      this.showRidesModal = true;
      if (row.pos.ride !== null) {
        this.ridesList = 
          {
            'title': row.pos.address.address.name +', '+ row.pos.address.address.city,
            'rides': {
            'Laden': row.pos.ride.load,
            'Abfahrt': row.pos.ride.departure,
            'Abladen vor Ort': row.pos.ride.uload,
            'Montagebeginn': row.pos.ride.assembly,
            'Aufladen vor Ort': row.pos.ride.load_back,
            'Abladen bei APA': row.pos.ride.unload_back
            }
          };
      }
    },
    showComments(row) {
      this.getPL().then(
        result => {
          this.isPL = result.length ? true : false;
        }
      );
      this.showCommentModal = true;
      this.commentItem = row;
    },
    async getPL() {
      if (this.project) {
        const persons = await this.getProjectPersons(this.project.id);
        let pl = persons.filter(
          (p) => (p.user_id === this.currentUser.id && p.topic === 'PL')
        )
      return pl;
      }
    },
    openExportModal() {
      this.exportModal = true;
    },
    onKommOrteChange(row, e) {
      row.komm_orte = e.join(",");
      this.$store.dispatch("updateMaterial", {
        id: row.id,
        data: {
          komm_orte: row.komm_orte,
        },
      });
    },
    onFilterRidesChanged(e) {
      this.filterRides = [...new Set(e)];
    },
    onFilterLocationsChanged(e) {
      this.filterLocations = [...new Set(e)];
    },
    onFilterPtNumberChanged(e) {
      this.filterPtNumber = [...new Set(e)];
    },
    onFilterCategoryChanged(e) {
      this.filterCategories = [...new Set(e)];
    },
    onFilterAreaChanged(e) {
      this.filterArea = [...new Set(e)];
    },
    imageUrl(item, path) {
      if (item.pos.documents) {
        const found = item.pos.documents
          .filter((doc) => doc.path === path)
          .reverse();
        if (found.length > 0) {
          if (isImage(found[0].mimetype)) {
            return found[0].url;
          }
          return "/app/img/empty.png";
        }
      }
      return "/app/img/empty.png";
    },
    hasImageUrl(item, path) {
      if (item.pos.documents && item.type !== 'KON') {
        const found = item.pos.documents
          .filter((doc) => doc.path === path)
          .reverse();
        if (found.length > 0) {
          if (isImage(found[0].mimetype)) {
            return true;
          }
          return false;
        }
      }
      return false;
    },
    openImage(item) {
      this.imagePreview = this.imageUrl(item, 'designs');
      this.showImageModal = true;
    },
    addItem() {
      this.$router.push({ path: `/project/${this.$route.params.id}/data/add` });
    },
    copySelection() {},
    deleteSelection() {},
    handleSelectionChange(val) {
      this.sel = val;
    },
    changeSelected(field, value) {
      this.sel.forEach((pos) => {
        if (pos[field] !== value) {
          pos[field] = value;
          this.changed.push(pos);
        }
      });
    },
    saveChanged() {
      const data = {};
      this.changed.forEach((pos) => (data[pos.id] = pos));
      this.$store
        .dispatch("updatePosition", { data: data })
        .then(() => (this.changed = []));
    },
    changeState(item, value = null) {
      if (value !== null) {
        item.user_komm_ref = this.currentUser;
        item.date_komm = new Date();
      }

      item.state_komm = state(item.komm_bemerkung);

      const action = item.additional
        ? "updateMaterial_additional"
        : "updateMaterial";

      if (item.ids) {
        item.ids.forEach((id) => {
          this.$store.dispatch(action, {
            id,
            data: {
              state_komm: item.state_komm,
              komm_bemerkung: item.komm_bemerkung,
            },
          });
        });
      } else {
        this.$store.dispatch(action, {
          id: item.id,
          data: {
            state_komm: item.state_komm,
            komm_bemerkung: item.komm_bemerkung,
          },
        });
      }

      function state(bemerkung) {
        if (value) {
          return "KOMMISSIONIERT";
        } else if (bemerkung) {
          return "BEMERKUNG";
        } else {
          return "OFFEN";
        }
      }
    },
    checkComment(item) {
      item.komm_bemerkung_checked = moment().format("YYYY-MM-DD HH:mm:ss");
      const action = item.additional
        ? "updateMaterial_additional"
        : "updateMaterial";

      this.$store.dispatch(action, {
          id: item.id,
          data: {
            komm_bemerkung_checked: item.komm_bemerkung_checked
          },
        });
    },
    tableRowClassName({ row, rowIndex }) {
      let classes = "no-hover";
      if (this.$route.params.positionId) {
        let pos_item = parseInt(this.$route.params.positionId);
        if (pos_item === row.position_id || pos_item === row.id) classes += " highlighted";
      }
      if (row.state_komm === "KOMMISSIONIERT") classes += " commissioned";
      if (row.pos.rel_business === 0) classes += " not-approved";
      if (rowIndex % 2 == 0) classes += " striped";
      return classes;
    },
    async reloadAdditional() {
      if (this.project) {
        this.additional = await this.loadAdditional(this.project.id);
      }
    },
    nextPosNumber() {
      if (this.additional.length == 0) return 1000;
      const nums = this.additional.map((pos) => {
        var matches = pos.key.match(/\d+$/);
        if (matches) {
          return parseInt(matches[0]);
        }
        return 0;
      });
      return Math.max(...nums);
    },
    setNewKey(key) {
      this.nextKey = key;
      this.edit(null)
    },
    newAdditional() {
      return {
        key: this.nextKey !== null ? this.nextKey : this.pad(this.nextPosNumber() + 1, 4),
        project_id: this.project.id,
        state_komm: "OFFEN",
        state_lade: "OFFEN",
        state_rueck: "OFFEN",
        state_mont: "OFFEN",
      };
    },
    pad(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },
    edit(item) {
      this.item = item ? Object.assign({}, item) : this.newAdditional();
      this.showWorkingAidModal = true;
    },
    showCopyAdditionalMaterialsModal() {
      this.isVisibleCopyAdditionalMaterialsModal = true;
    },
    async copyAdditionalMaterials() {
      try {
        const { id } = this.$route.params;
        await Vue.http.get(
          `material-additional/${id}/copy/${this.projectToCopyFrom}/${this.rideToCopyFrom}/${this.locationToCopyFrom}`
        );
        this.isVisibleCopyAdditionalMaterialsModal = false;
        this.reloadAdditional();
      } catch (error) {
        console.log(error);
      }
    },
    askDelete(item) {
      this.itemDelete = item;
      this.showDeleteWorkingAidModal = true;
    },
    async deleteAdditional() {
      await this.$store.dispatch("deleteMaterial_additional", {
        id: this.itemDelete.id,
      });
      this.showDeleteWorkingAidModal = false;
      this.reloadAdditional();
    },
    async saveNewOption(type) {
      const data = {
        type,
        name: this.newPickingValue
      }
      await this.$store.dispatch("createOption", { data });
      this.newPickingValue = ''
    },
    toggleCheck() {
      const action = "updateProject";
      const date = moment().format("YYYY-MM-DD HH:mm:ss");
      this.$store.dispatch(action, {
        id: this.project.id,
        data: {
          komm_checked: date,
          komm_checked_user: this.currentUser.id,
        },
      });
      this.getFullProject(this.project.id);
    },
    onCurrentChange(pageNumber) {
      this.currentPage = pageNumber;
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Summe:";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = "";
        }
      });

      return sums.map((s, i) =>
        i > 0 && s !== "" ? new Intl.NumberFormat("de").format(s) : s
      );
    },
  },
  async mounted() {
    this.reloadAdditional();
    this.loadGlobalOptions();
  },
};
</script>
<style>
.checkbox-lg .el-checkbox ::before,
.checkbox-lg .el-checkbox ::after {
  top: 0.8rem;
  width: 1.55rem;
  height: 1.55rem;
}

.checkbox-lg .el-checkbox {
  padding-top: 13px;
  padding-left: 6px;
}

.checkbox-xl .el-checkbox ::before,
.checkbox-xl .el-checkbox ::after {
  top: 1.2rem;
  width: 1.85rem;
  height: 1.85rem;
}

.project-selection {
  width:100%;
}

.el-table__footer-wrapper > .el-table__footer > tbody > tr > td {
  padding-right: 0;
}

.checkbox-xl .el-checkbox {
  padding-top: 23px;
  padding-left: 10px;
}
.el-table .striped {
  background: #fafafa !important;
}
.el-table .commissioned {
  background: #dddddd !important;
}
.el-table .not-approved {
  background: rgba(255,0,0,0.15) !important;
}
.ride-name {
  display: inline-block;
  width: 150px;
  margin: 5px 0;
}
.el-table .highlighted {
  background: rgba(133, 193, 248, 0.3) !important;
}
.konfektion {
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.konfektion-red {
  background-color: #f00;
}
.konfektion-yellow {
  background-color: #ff0;
}
.konfektion-green {
  background-color: #0f0;
}
.tablet-show {
  display: none;
}
@media (max-width: 1200px) {
  .tablet-hide {
    display: none;
  }
  .tablet-show {
    display: inline-block;
  }
.el-table td,
.el-table td .btn-sm {
  font-size: .7rem;
}
.el-table thead th {
  font-size: .6rem;
}
}
</style>
